:where(.root) {
	margin-block: 0;
	margin-inline: 0;
	border: 0;
	padding: 0;
	background-color: transparent;
	color: inherit;
	font-weight: inherit;

	&:where(.display1) {
		@extend %typo-display-1;
	}

	&:where(.display2) {
		@extend %typo-display-2;
	}

	&:where(.display3) {
		@extend %typo-display-3;
	}

	&:where(.h1) {
		@extend %typo-heading-1;
	}

	&:where(.h2) {
		@extend %typo-heading-2;
	}

	&:where(.h3) {
		@extend %typo-heading-3;

		&:where(.bold) {
			font-weight: 600;
		}
	}

	&:where(.h4) {
		@extend %typo-heading-4;

		&:where(.bold) {
			font-weight: 600;
		}
	}

	&:where(.h5) {
		@extend %typo-heading-5;

		&:where(.bold) {
			font-weight: 600;
		}
	}

	&:where(.body) {
		@extend %typo-body-1;
	}

	/* stylelint-disable-next-line no-duplicate-selectors */
	&:where(.body) {
		&:where(.bold) {
			font-weight: 500;
		}
	}

	&:where(.body2) {
		@extend %typo-body-2;
	}

	&:where(.body3) {
		@extend %typo-body-3;
	}

	&:where(.utility1) {
		@extend %typo-utility-1;
	}

	&:where(.utility),
	&:where(.none) {
		&:where(.bold) {
			font-weight: 500;
		}
	}

	&:where(.utility2) {
		@extend %typo-utility-2;
	}

	&:where(.utility3) {
		@extend %typo-utility-3;
	}

	&:where(.utility4) {
		@extend %typo-utility-4;
	}

	&:where(.utility5) {
		@extend %typo-utility-5;
	}

	&:where(.utility6) {
		@extend %typo-utility-6;
	}

	@media (--md) {
		&:where(.md_display1) {
			@extend %typo-display-1;
		}

		&:where(.md_display2) {
			@extend %typo-display-2;
		}

		&:where(.md_display3) {
			@extend %typo-display-3;
		}

		&:where(.md_h1) {
			@extend %typo-heading-1;
		}

		&:where(.md_h2) {
			@extend %typo-heading-2;
		}

		&:where(.md_h3) {
			@extend %typo-heading-3;

			&:where(.bold) {
				font-weight: 600;
			}
		}

		&:where(.md_h4) {
			@extend %typo-heading-4;

			&:where(.bold) {
				font-weight: 600;
			}
		}

		&:where(.md_h5) {
			@extend %typo-heading-5;

			&:where(.bold) {
				font-weight: 600;
			}
		}

		&:where(.md_body) {
			@extend %typo-body-1;

			&:where(.bold) {
				font-weight: 500;
			}
		}

		&:where(.md_body2) {
			@extend %typo-body-2;
		}

		&:where(.md_body3) {
			@extend %typo-body-3;
		}

		&:where(.md_utility1) {
			@extend %typo-utility-1;
		}

		&:where(.md_utility2) {
			@extend %typo-utility-2;
		}

		&:where(.md_utility3) {
			@extend %typo-utility-3;
		}

		&:where(.md_utility4) {
			@extend %typo-utility-4;
		}

		&:where(.md_utility5) {
			@extend %typo-utility-5;
		}

		&:where(.md_utility6) {
			@extend %typo-utility-6;
		}
	}

	&:where(.uppercase) {
		text-transform: uppercase;
	}

	&:where(.center) {
		text-align: center;
	}

	&:where(.mxAuto) {
		margin-inline: auto;
	}

	&:where(.block) {
		display: block;
	}

	&:where(.underline) {
		text-decoration: underline;
	}

	&:where(.maxWidthContent) {
		max-width: var(--content-width);
	}

	&:where(.colorPrimary) {
		color: var(--text-primary);
	}

	&:where(.colorRed) {
		color: var(--accent-red);
	}

	&:where(.colorSecondary) {
		color: var(--text-secondary);
	}

	&:where(.colorGrey) {
		color: var(--primary-grey);
	}

	&:where(.colorGreige) {
		color: var(--primary-greige);
	}

	&:where(.colorWhite) {
		color: var(--primary-white);
	}

	&:where(.colorDarkBlue06) {
		color: var(--primary-dark-blue-06);
	}

	&:where(.colorDarkBlue08) {
		color: var(--primary-dark-blue-08);
	}

	&:where(.w500) {
		font-weight: 500;
	}

	&:where(.w600) {
		font-weight: 600;
	}

	&:where(.w400) {
		font-weight: 400;
	}

	&:where(.error) {
		color: var(--accent-red);
	}

	&:where(.success) {
		color: var(--accent-green);
	}
}
